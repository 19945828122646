import { FC, lazy, useEffect, useState } from "react";
import { Navigate, RouteObject } from "react-router-dom";

import { Layout } from "@/components/layout/Layout";
import { RegisteredLayout } from "@/components/layout/RegisteredLayout";
import { RegisteredLayoutWithoutSider } from "@/components/layout/RegisteredLayoutWithoutSider";
import { Loader } from "@/components/ui/Loader";
import { useAuthStore } from "@/store/auth";
import { getAuthToken } from "@/utils/auth";
import { useUserRoleStore } from "@/store/userStore"; // Для отримання ролі користувача

const BadConnection = lazy<FC>(() => import("./bad-connection"));
const Customers = lazy<FC>(() => import("./customers"));
const ForgotPassword = lazy<FC>(() => import("./forgot-password"));
const TaskDetails = lazy<FC>(() => import("./projects/task-details"));
const InvoiceDetails = lazy<FC>(() => import("./invoices/invoice-details"));
const Home = lazy<FC>(() => import("./home"));
const Invoices = lazy<FC>(() => import("./invoices"));
const NotFound = lazy<FC>(() => import("./not-found"));
const Profile = lazy<FC>(() => import("./profile"));
const Projects = lazy<FC>(() => import("./projects"));
const SignIn = lazy<FC>(() => import("./sign-in"));
const ServiceCalls = lazy<FC>(() => import("./service-calls"));
const Tasks = lazy<FC>(() => import("./tasks"));
const Team = lazy<FC>(() => import("./team"));
const Timesheets = lazy<FC>(() => import("./timesheets"));

const authRoutes: RouteObject[] = [
  {
    Component: Layout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Home,
        path: "/",
      },
      {
        Component: SignIn,
        path: "/sign-in",
      },
      {
        Component: ForgotPassword,
        path: "/forgot-password",
      },
    ],
  },
  {
    Component: BadConnection,
    path: "/bad-connection",
  },
  {
    Component: NotFound,
    path: "/not-found",
  },
  {
    element: <Navigate to={"/not-found"} replace />,
    path: "*",
  },
];

const privateRoutes: RouteObject[] = [
  {
    Component: RegisteredLayout,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: Projects,
        path: "/projects",
        children: [
          {
            Component: Projects,
            path: ":noxeId",
          },
        ],
      },
      // {
      //   Component: TaskDetails,
      //   path: "/projects/:noxeId/task-details/:taskId",
      // },
      // {
      //   Component: TaskDetails,
      //   path: "/projects/task-details/:taskId",
      // },
      // {
      //   Component: InvoiceDetails,
      //   path: "/invoices/invoice-details",
      // },
      {
        Component: ServiceCalls,
        path: "/service-calls",
      },
      {
        Component: Tasks,
        path: "/tasks",
      },
      {
        Component: Customers,
        path: "/customers",
      },
      {
        Component: Invoices,
        path: "/invoices",
      },
      {
        Component: Team,
        path: "/team",
      },
      {
        Component: Timesheets,
        path: "/timesheets",
      },
      {
        Component: Profile,
        path: "/profile",
      },
    ],
  },
  {
    Component: RegisteredLayoutWithoutSider,
    loader: () => <Loader spinning />,
    children: [
      {
        Component: TaskDetails,
        path: "/projects/:noxeId/task-details/:taskId",
      },
      {
        Component: TaskDetails,
        path: "/service-calls/:taskId",
      },
      {
        Component: InvoiceDetails,
        path: "/invoices/:noxeId",
      },
      {
        Component: InvoiceDetails,
        path: "/projects/:projectId/invoices/:invoiceId",
      },
    ],
  },
];

export const routes = (authorized: boolean): RouteObject[] => {
  const setStore = useAuthStore((store) => store.set);
  const role = useUserRoleStore((state) => state.role);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loggedIn = getAuthToken();

    if (loggedIn) {
      setStore((store) => {
        store.loggedIn = true;
      });
    }

    setLoading(false);
  }, []);

  if (loading) {
    return [
      {
        element: <Loader spinning />,
        path: "*",
      },
    ];
  }


  let filteredPrivateRoutes = privateRoutes;

  if (role) {
    if (role.toLowerCase() === "technician") {
      filteredPrivateRoutes = privateRoutes.map((route) => {
        if (route.children) {
          return {
            ...route,
            children: route.children.filter((childRoute) =>
              ["tasks", "timesheets", "profile", "task-details"].some(keyword => childRoute.path?.includes(keyword))
            ),
          };
        }
        return route;
      });
    } else if (role.toLowerCase() === "accountant") {
      filteredPrivateRoutes = privateRoutes.map((route) => {
        if (route.children) {
          return {
            ...route,
            children: route.children.filter((childRoute) =>
              ["profile", "invoices"].some(keyword => childRoute.path?.includes(keyword))
            ),
          };
        }
        return route;
      });
    }else if (role.toLowerCase() === "project_manager") {
      filteredPrivateRoutes = privateRoutes.map((route) => {
        if (route.children) {
          return {
            ...route,
            children: route.children.filter((childRoute) =>
              ["profile", "invoices", "task-details", "tasks"].some(keyword => childRoute.path?.includes(keyword))
            ),
          };
        }
        return route;
      });
    }
  }

  if (authorized) {
    return [...authRoutes, ...filteredPrivateRoutes];
  } else {
    return [...authRoutes];
  }
};

export default routes;
